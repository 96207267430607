import { render, staticRenderFns } from "./RoomList.vue?vue&type=template&id=4804bb3f&scoped=true"
import script from "./RoomList.vue?vue&type=script&lang=js"
export * from "./RoomList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4804bb3f",
  null
  
)

export default component.exports