<template>
  <div id="Site">
    <h1>{{ $tc('views.nd.netdoc.room', 2) }} <span v-if="bldg">in {{ bldg.number }}</span></h1>
    <LoadingPlaceholder :data="[bldg, rooms]">
      <b-row class="mt-4">
        <b-col cols="6">
          <div v-if="bldg">
            <div style="float: left;">
              <NETVSIcon icon="geo_address" class="pr-2"></NETVSIcon>
            </div>
            <div style="float: left;">
              {{ bldg.name }}
              <br>
              {{ bldg.street }}
              <br>
              {{ bldg.postal_code }}
            </div>
          </div>
        </b-col>
        <b-col cols="6">
          <KITMap v-if="can_show_map" :center="bldg.geo_location" :zoom="17" style="height: 20vh; width: 100%;">
            <template v-slot:map_content>
              <l-marker :lat-lng="latLng(bldg.geo_location.lat, bldg.geo_location.lng)"
                        :icon="icon"></l-marker>
            </template>
          </KITMap>
        </b-col>
      </b-row>
      <RoomList :rooms="rooms" :bldgs="[bldg]" />
    </LoadingPlaceholder>
  </div>
</template>

<script>
import LoadingPlaceholder from '@/components/Loading.vue'
import netdocService from '@/api-services/netdoc.service'
import RoomList from '@/components/nd/RoomList.vue'
import NETVSIcon from '@/icons/NETVSIcon.vue'
import KITMap from '@/components/KITMap.vue'
import {icon, latLng} from 'leaflet'
import {LMarker} from 'vue2-leaflet'

export default {
  name: 'NetdocBldg',
  methods: { latLng },
  components: { KITMap, NETVSIcon, RoomList, LoadingPlaceholder, LMarker },
  data() {
    return {
      site: null,
      bldg: null,
      rooms: null,

      icon: icon({iconUrl: '/location.png', iconSize: [32, 42], iconAnchor: [16, 42]})
    }
  },
  async created() {
    const response = await netdocService.getRooms(this.$store.state, this.$route.params.site, this.$route.params.bldg)
    this.site = response.data.site_list[0]
    this.bldg = response.data.bldg_list[0]
    console.log(this.bldg)
    this.rooms = response.data.room_list
  },
  computed: {
    can_show_map: function () {
      return this.bldg && this.bldg.geo_location && this.bldg.geo_location.lat && this.bldg.geo_location.lng
    }
  },

}
</script>

<style scoped>

</style>
